import image31 from '../GalleryImages/image31.jpg'
import image32 from '../GalleryImages/image32.jpg'
import image33 from '../GalleryImages/image33.jpg'
import image34 from '../GalleryImages/image34.jpg'
import image35 from '../GalleryImages/image35.jpg'

import image1 from '../GalleryImages/image1.png'
import image2 from '../GalleryImages/image2.png'
import image3 from '../GalleryImages/image3.png'
import image4 from '../GalleryImages/image4.png'
import image5 from '../GalleryImages/image5.png'
import image6 from '../GalleryImages/image6.png'
import image7 from '../GalleryImages/image7.png'
import image8 from '../GalleryImages/image8.png'
import image9 from '../GalleryImages/image9.png'
import image10 from '../GalleryImages/image10.png'
import image11 from '../GalleryImages/image11.png'
import image12 from '../GalleryImages/image12.png'
import image13 from '../GalleryImages/image13.png'
import image14 from '../GalleryImages/image14.png'
import image15 from '../GalleryImages/image15.png'
import image16 from '../GalleryImages/image16.png'
import image17 from '../GalleryImages/image17.png'
import image18 from '../GalleryImages/image18.png'
import image19 from '../GalleryImages/image19.png'
import image20 from '../GalleryImages/image20.png'
import image21 from '../GalleryImages/image21.png'
import image22 from '../GalleryImages/image22.png'
import image24 from '../GalleryImages/image24.png'
import image25 from '../GalleryImages/image25.png'
import image26 from '../GalleryImages/image26.png'
import image27 from '../GalleryImages/image27.png'
import image29 from '../GalleryImages/image29.jpg'
import image30 from '../GalleryImages/image30.jpg'
let images = [
image1, image2, image3, image4, image5, image6, 
image7, image8, image9, image10, image11, image12, 
image13, image14, image15, image16, image17, image18, 
image19, image20, image21, image22,  image24, 
image25, image26, image27,  image29, image30, image31,
image32, image33, image34, image35,
]

export {images}