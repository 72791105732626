import React from 'react'
import '../css/hamburger.css'
import '../css/header.css'

export default function Hamburger(){
    return(
        <>
                <div>
                    <div className='item' />
                    <div className='item' />
                    <div className='item' />
            </div>
        </>

    )
}