let appetizers=[
'Chicken Fingers','Deviled Eggs', 'Fried Ravioli*','Jalapeno Poppers*', 'Mozzarella Sticks*', 'Onion Rings', 'Pigs in a Blanket', 'Shrimp Coctail (+$10)', 'Stuffed Mushrooms',
];

let salads=[
'House Salad *' , 'Ceasar Salad', 'Chicken Cesar Salad', 'Coleslaw', 'Macaroni Salad', 'Pasta Salad', 'Potato Salad',
];

let entrees=[
    'Baked Ziti', 'Baked Ham & Pineapple', 'Chicken Alfredo', 'Chicken Parmesan', 'Cod (+$10)', 'Crab Cakes(+$10)', 'Eggplant Parmesan', 'Lasagna', 'Meatballs', 'Sausage with Onions & Green Peppers', 'Shrimp Scampi(+$10)',
];

let sides=[
    'Baked Potato', 'Broccoli','Buttered Corn', 'Carrots', 'Cauliflower', 'French Fries', 'Green Beans', 'Macaroni & Cheese', 'Mashed Potatoes', 'Peas', 'Rice Pilaf', 'Vegetable Medley',
]
let kids=[
    'Hamburgers', 'Cheeseburgers', 'Chicken Fingers', 'Grilles Cheese', 'Hot Dogs', 'Spaghetti'
]

let cakes=[
    'Vanilla', 'Chocolate', 'Carrot', 'Cheesecake: Plain, Cherry, or Strawberry'
]
let pies=[
    'Apple', 'Cherry', 'Coconut Custard',
]


export {
    appetizers,
    salads,
    entrees,
    sides,
    kids,
    cakes,
    pies,

}