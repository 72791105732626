import React from 'react'
import '../css/hamburgeropen.css'
import '../css/header.css'

export default function HamburgerOpen(){
    return(
        <>
                <div>
                    <div className='item item1' />
                    <div className='item item2' />
                    <div className='item item3' />
            </div>
        </>

    )
}